/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    sim: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1.5A1.5 1.5 0 013.5 0h7.086a1.5 1.5 0 011.06.44l1.915 1.914A1.5 1.5 0 0114 3.414V14.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 14.5zM3.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V3.414a.5.5 0 00-.146-.353l-1.915-1.915A.5.5 0 0010.586 1z"/><path pid="1" d="M5.5 4a.5.5 0 00-.5.5V6h2.5V4zm3 0v2H11V4.5a.5.5 0 00-.5-.5zM11 7H5v2h6zm0 3H8.5v2h2a.5.5 0 00.5-.5zm-3.5 2v-2H5v1.5a.5.5 0 00.5.5zM4 4.5A1.5 1.5 0 015.5 3h5A1.5 1.5 0 0112 4.5v7a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 11.5z"/>',
    },
});
